import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import JoinUs from '../sections/joinUs'
import { PageHead } from '../common'
import Products from '../sections/content-products'

export default ({ data }) => (
  <Layout headImg={`https://underwoods.s3.amazonaws.com/header_estate.jpg`}>
    <SEO title="About Us" />
    <PageHead>
      <h1>ANTIQUE & ESTATE</h1>
      <p>Underwood’s in Avondale proudly offers an Antique and Estate jewelry case with beautiful items from the past which have been refurbished for daily wear. We would love for you to visit and browse our Estate collection of Vintage, Art Deco, Edwardian jewelry and pieces from many different eras. Each item is hand selected by one of our Certified Gemologists and assessed for its rarity, quality and beauty. Wearing vintage, antique and estate jewelry is a wonderful way to show your unique and timeless style.</p>
    </PageHead>
    <Products data={data} title={`Estate Jewelry`} link='/products/estate'/>
    <JoinUs />
  </Layout>
)

export const EngRingQuery = graphql`
  query EstatePageQuery {
  postgres {
    allProductsItems(first: 3, orderBy: ID_DESC, condition: {designer: "Underwood's Estate"}) {
      edges {
        node {
          title
          designer
          image
        }
      }
    }
  }
}
`
